<template>
  <header>
    <div class="content">
      <div class="left">
        <a href="/"><img class="logo" src="@/assets/images/logo.png" alt=""></a>
        <i class="iconfont duozhi-icon-tianmaoxingxiang2" :style="showSearch && 'width: 0px;margin: 0px'"></i>
        <i class="iconfont duozhi-icon-jingdong" :style="showSearch && 'width: 0px;margin: 0px'"></i>
      </div>
      <div class="right">
        <div class="screen">
          <input type="text" v-model="keyword" @keyup.enter="screen()" placeholder="请输入关键字" />
          <a :href="'/product/?keyword='+keyword" class="button">搜索</a>
        </div>
        <div class="screen-m">
          <i class="iconfont duozhi-icon-fangdajing" @click="showSearch = !showSearch"></i>
          <input class="search-box" :style="!showSearch && 'width: 0px;margin: 0px;'" type="text" v-model="keyword" @keyup.enter="screen()" placeholder="请输入关键字" />

          <i class="iconfont duozhi-icon-gengduo" @click="() => $store.commit('setData', {key: 'menuShow', value: !this.menuShow})"></i>

        </div>
        <!-- <div class="lang">
          <div :class="$i18n.locale == 'cn' && 'select'" @click="setLang('cn')">中文</div>
          <div :class="$i18n.locale == 'en' && 'select'" @click="setLang('en')">English</div>
        </div> -->
      </div>
    </div>
  </header>
</template>

<script>
import {setCache} from "@/utils/methods.js"
export default {
  data(){
    return {
      keyword: "",
      showSearch: false
    }
  },
  computed: {
    menuShow(){
      return this.$store.state.menuShow
    }
  },
  created(){
    console.log(this.$i18n)
    console.log(this.$i18n.locale)
  },
  methods: {
    setLang(lang){
      this.$i18n.locale = lang
      setCache("lang", lang)
    },
    screen(){
      window.location.href='/product/?keyword='+this.keyword
    }
  }
}
</script>

<style lang="scss" scoped>

header{
  height: 90px;
  line-height: 90px;
  .content{
    .left{
      float: left;
      height: 90px;
      display: flex;
      align-items: center;
      a{
        height: 60px;
        display: block;
      }
      .logo{
        height: 60px;
        margin-right: 20px;
        cursor: pointer;
      }
      .iconfont{
        width: 40px;
        height: 40px;
        background: #f4f4f4;
        line-height: 40px;
        font-size: 25px;
        border-radius: 50%;
        cursor: pointer;
        margin-left: 14px;
        transition: all 50s;
        transition-timing-function: cubic-bezier(0.13, 0.55, 1, 0.41);
        overflow: hidden;
      }
      .iconfont:hover{
        transition: all 5s;
        transform: rotate(36000deg);
      }
    }
    .right{
      float: right;
      display: flex;
      align-items: center;
      height: 90px;
      .screen{
        height: 30px;
        border: 1px solid #ccc;
        display: flex;
        border-radius: 20px;
        overflow: hidden;
        margin-right: 20px;
        input{
          border: 0;
          outline: none;
          padding: 0 20px;
          font-size: 16px;
          width: 100px;
          transition: all .4s;
        }
        .button{
          color: white;
          line-height: 30px;
          background: #ccc;
          padding: 0 20px;
          cursor: pointer;
        }
      }
      .screen:hover{
        input{
          width: 160px;
        }

      }
      .lang{
        display: flex;
        line-height: 30px;
        font-weight: 500;
        & > div{
          padding: 0 10px;
          cursor: pointer;
          position: relative;
          &::before{
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            left: 50%;
            top: 50%;
            background: #ccc;
            z-index: -1;
            transition: all .2s;
          }
        }
        .select{
          &::before{
            width: 20px;
            height: 20px;
            left: calc(50% - 10px);
            top: calc(50% - 10px);
            border-radius: 10px;
          }
        }
      }
      .screen-m{
        display: none;
        align-items: center;
        .search-box{
            width: 100px;
            margin-left: 10px;
            border-radius: 4px;
            border: 0;
            // padding-left: 5px;
            box-sizing: border-box;
            transition: all 1s;
            overflow: hidden;
            padding-left: 0;
            padding-right: 0;
                outline-style: none;
            
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  header{
    height: 70px;
    line-height: 70px;
    position: sticky;
    top: 0;
    z-index: 99;
    background: white;
    border-bottom: 1px solid rgba(204, 204, 204, .16);
    box-shadow: 0 3px 4px 0 rgb(0 0 0 / 4%);
    .content{
      .left {
        height: 70px;
        a{
          height: 45px;
        }
        .logo{
          height: 45px;
        }
        .iconfont{
          width: 30px;
          height: 30px;
          line-height: 30px;
          font-size: 20px;
          transition: all 1s!important;
        }
      }
      .right{
        height: 70px;
      }
      .screen{
        display: none!important;
      }
      .screen-m{
        margin-right: 10px;
        display: flex!important;
        .iconfont{
          width: 30px;
          height: 30px;
          background: #f4f4f4;
          line-height: 30px;
          font-size: 20px;
          border-radius: 50%;
          margin-left: 14px;
          transition: all 50s;
        }
      }

    }
  }
}

</style>